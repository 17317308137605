
<template>
    <div class="app-product-list">
      <b-overlay :show="show" no-wrap fixed z-index="9999">
        <template v-slot:overlay>
            <div class="d-flex align-items-center">
              <b-spinner small type="grow" variant="dark"></b-spinner>
              <b-spinner type="grow" variant="dark"></b-spinner>
              <b-spinner small type="grow" variant="dark"></b-spinner>
            </div>
          </template>
      </b-overlay>
      <router-link class="btn btn-success" to="/admin/products/create">ثبت محصول جدید</router-link>
      <b-card v-if="items.data" :title="items.data.data ? 'محصولات : ' + items.data.total + 'محصول ' : 'محصولات '" class="mt-3">
        
        <b-button v-b-toggle.filterBox variant="primary" class="mb-3 position-absolute" style="top:20px; left: 20px;"
        size="sm"><i class="fa fa-bars"></i></b-button>
      <b-collapse id="filterBox" class="my-2">
        <b-card>
          <form @submit.prevent="searchItems()" id="search-item">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="title">عنوان</label>
                  <b-form-input id="title" v-model="searchParams.title"></b-form-input>
                </div>
              </div>
              <div class="col-md-4">
                <b-form-group label="دسته بندی" >
                  <!-- <input type="hidden" name="category_id[]" v-for="item in category" :key="item.id" :value="item.id"> -->
                  <multiselect open-direction="bottom" v-model="category" :options="categories" label="name" track-by="id" :multiple="true" :searchable="true"
                  :close-on-select="true"
                  :show-labels="false"></multiselect>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <b-form-group label="وضعیت" label-for="status">
                  <b-form-select id="status" v-model="searchParams.status">
                    <b-form-select-option value="available">موجود</b-form-select-option>
                    <b-form-select-option value="unavailable">ناموجود</b-form-select-option>
                    <b-form-select-option value="inactive">غیرفعال</b-form-select-option>
                    <b-form-select-option value="soon">به زودی</b-form-select-option>
                    <b-form-select-option value="">همه</b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </div>
            </div>
            <button class="btn btn-primary btn-sm float-left" type="submit" :disabled="disabled">جست و
              جو</button>
          </form>
        </b-card>
      </b-collapse>
        <b-table
          responsive
          striped
          hover
          v-if="items.data"
          :fields="table_fields"
          :items="items.data.data">

          <template v-slot:cell(created_at)="data">
            {{ data.item.created_at | persianDate }}
          </template>
          <template v-slot:cell(other)="data">
            <b-dropdown id="dropdown-menu" dropright variant="info" no-caret>
              <template #button-content>
                <i class="fas fa-ellipsis-v"></i>
              </template>
              <b-dropdown-item @click="loadProductsRelated(data.index)"> 
                <span>محصولات مرتبط</span>
                <!-- <router-link :to="{ name: 'products.productsRelated', params: { product: data.item.id } }">
                  محصولات مرتبط
                </router-link> -->
              </b-dropdown-item>
            </b-dropdown>
          </template>
          <template v-slot:cell(status)="data">
            <span class="badge badge-success" v-if="data.item.status == 'available'">موجود</span>
            <span class="badge badge-light" v-else-if="data.item.status == 'unavailable'">ناموجود</span>
            <span class="badge badge-warning" v-else-if="data.item.status == 'inactive'">غیرفعال</span>
            <span class="badge badge-info" v-else-if="data.item.status == 'soon'">به زودی</span>
          </template>
          <template v-slot:cell(edit)="data">
              <!-- <a class="btn btn-primary" :href="'/admin/products/edit' + data.item.id">
                <i class="fa fa-edit"></i>
              </a> -->
              <router-link class="btn btn-primary" :to="{name:'products.edit.index' , params:{product: 'edit' , id:data.item.id}}">
                <i class="fa fa-edit"></i>
              </router-link>
          </template>
          <template v-slot:cell(delete)="data">
              <button class="btn btn-danger" @click="deleteItem(data.item.id)"><i class="fa fa-close"></i></button>
          </template>
        </b-table>
        <div class="alert alert-danger text-right" v-if="items.data && items.data.data == ''">
          <span class="text-right">موردی یافت نشد ! </span>
        </div>
      </b-card>

      <pagination :limit="2" v-if="items.data && !search" :data="items.data" @pagination-change-page="loadItems">
      </pagination>
      <pagination :limit="2" v-else-if="items.data && search" :data="items.data" @pagination-change-page="searchItems">
      </pagination>

      <b-modal id="product-related" hide-footer title="محصولات مرتبط">
        <form id="related-item" @submit.prevent="relatedItem">
          <b-form-group label="محصولات" >
            <multiselect
                  :close-on-select="false"
                  :clear-on-select="false"
                  deselectLabel=""
                  selectLabel=""
                  selectedLabel=""
                  v-model="related_select"
                  :options="productsAll"
                  :multiple="true"
                  label="title"
                  track-by="id"
                  placeholder=""
                ></multiselect>
                <div v-for="item in related_select" :key="item.id">
                  <input type="hidden" name="related_id[]" :value="item.id">
                </div>
          </b-form-group>
          <input type="hidden" name="product_id" :value="productsRelatedId">
          <!-- <input type="hidden" name="related_id" :value="related_select"> -->
          <button class="btn btn-success mt-3" type="submit" :disabled="disabled">ثبت</button>
        </form>
      </b-modal>
    </div>
  </template>

<script>
import mixins from '../mixins/mixins'
export default {
  mixins: [mixins],
  data () {
    return {
      url: '/api/admin/products',
      title: 'محصولات',
      table_fields: [
        { key: 'id', label: 'شناسه ' },
        { key: 'title', label: 'عنوان' },
        // { key: 'in_stock', label: 'تعداد در انبار' },
        { key: 'status', label: 'وضعیت' },
        { key: 'in_storage', label: 'تعداد در انبار' },
        { key: 'sold_count', label: 'تعداد فروش' },
        { key: 'view_count', label: 'تعداد بازدید' },
        { key: 'created_at', label: 'تاریخ ثبت' },
        { key: 'edit', label: 'ویرایش ' },
        { key: 'delete', label: 'حذف' },
        { key: 'other', label: ' ' }
      ],
      categories:[],
      category:[],
      productsTotal:'',
      productsRelatedId:'',
      productsAll:[],
      related_select: [],
      index:''
    }
  },
  methods: {
    loadCategories () {
      this.$axios.get(this.$root.baseUrl + '/api/admin/categories?all=true')
      .then(response => {
        this.categories = response.data.data
      })
    },
    loadProductsRelated(index) {
      const item = window.clone(this.items.data.data[index])
      this.index = index
      this.related_select = item.related
      this.productsRelatedId = item.id
      this.$root.$emit('bv::show::modal', 'product-related')
    },
    relatedItem() {
      this.disabled = true
            let form = document.getElementById('related-item')
            let formData = new FormData(form)
            
            this.$axios.post(this.$root.baseUrl + '/api/admin/store/related' , formData)
              .then(response => {
                  this.$root.success_notification(response.data.message)
                this.items.data.data[this.index].related = response.data.data.related
                this.$root.$emit('bv::hide::modal', 'product-related')
              })
              .catch(error => {
                this.$root.error_notification(error)
              })
              .finally(() => {
                  this.disabled = false
              })
    },
    searchItems(page = 1) {
            this.disabled = true
            this.search = true
            this.searchParams.category_id = []
            this.category.map(item => this.searchParams.category_id.push(item.id))
    
            this.$axios.get(this.$root.baseUrl + this.url + '?page=' + page , {params: this.searchParams})
              .then(response => {
                this.search = true
                this.items = response.data
              })
              .catch(error => {
                this.$root.error_notification(error)
              })
              .finally(() => {
                this.disabled = false
              })
          },
  },
  created () {
    this.loadItems()
    this.loadCategories()

    // window.relatedItem = response => {
    //   if (response.success) {
    //     this.items.data.data[this.index].related = response.data.related
    //     this.success_notification('محصولات مرتبط با موفقیت ثبت شد')
    //     this.$root.$emit('bv::hide::modal', 'product-related')
    //   }
    // }
    this.searchParams = {
        title: '',
        status: '',
        category_id: []
      }
  },
  mounted () {
    this.$axios.get(this.$root.baseUrl + '/api/admin/stats')
    .then(response => {
      this.productsTotal = response.data.data.products.total
    })
    this.$axios.get(this.$root.baseUrl + '/api/admin/list/products')
    .then(response => {
      this.productsAll = response.data.data
          this.productsAll.forEach(item => {
            item.selected = false
          });
    })
  },
}
</script>
<style>
 .btn-group > .dropdown-menu {
    right:unset !important;
    left: -10px;
    bottom: 0;
  }
  #dropdown-menu .dropdown-menu > li > a {
    padding: 10px 20px;
  }
  .app-product-list .btn-group > .dropdown-menu{
    left: 0 !important;
    right: unset !important;
  }
</style>